<template>
  <div class="main">
    <Header></Header>
    <router-view></router-view>
    <div class="app-bg"></div>
    <template >
      <el-dialog
        :title="$t('_bind_._bind_')"
        :visible.sync="dialogVisible"
        :show-close="false"
        width="90%"
        :before-close="handleClose">
        <div class="dialog-content">
            <div class="max-input">
              <el-input type="text" v-model="referee" style="font-size:12px" placeholder="请输入绑定地址"/>
              <span>{{$t('_bind_._must_')}}</span>
            </div>
            <button @click="bindMsd">{{$t('_bind_._bind_')}}</button>
        </div>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import { ethers } from 'ethers'

import Header from '@/components/FomoHeader.vue'
// import { get, post } from '../../utlis/axios'
import { initSigner } from '../../utlis/index'
import MinerJson from "@/contracts/NewMiner.json";
import BindJson from "@/contracts/Bind.json";
const paths = ['/invest','/market','/my-reward']
export default {
  data () {
    return {
      contracts:{},
      referee:null,
      dialogVisible:false,
      isRoute:false,
      inputMyId:'',
      address:'',
      inputRefId:'',

    }
  },
  components: {
    Header
  },
  watch:{

  },
  mounted(){
    this.referee = this.$route.query.referee

    if(paths.includes(this.$route.path)) this.isRoute =  true;
    this.init()
  },
  methods:{

    async init(){
      let { address, provider, signer } = await initSigner()
      if(!address || !provider|| !signer) return;
      this.address = address;
      console.log(this.address,this.$common.FOMO.MinerHelper);
      this.contracts.Bind = new ethers.Contract(this.$common.FOMO.MinerHelper, MinerJson, provider).connect(signer);
      // let referAddress = await this.contracts.Bind.userMap(address);
      this.contracts.BindNew = new ethers.Contract(this.$common.FOMO.Miner, BindJson, provider).connect(signer);
      //
      // console.log(referAddress);
      // if (referAddress.active==false)
      // {
      //   this.dialogVisible = true;
      //   console.log(this.referee);
      // }

    },
    handleClose(){

    },
    async bindMsd(){

      // try {
      //   let referAddress = await this.contracts.Bind.userMap(this.referee);
      //   if (referAddress.active==false)
      //   {
      //       this.$message.error("地址未激活!")
      //       return;
      //   }
      //   await this.contracts.BindNew.bindRelationship(this.referee);
      //   this.dialogVisible = false;
      // }catch (e) {
      //    console.log(e);
      // }

    },

  }
}
</script>

<style lang="scss" scoped>
.main {
  // position: relative;
  color: #FFF;
  .app-bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: url('../../assets/img/dapp-bg.png') left top / cover no-repeat;
  }
}
  .dialog-content{
    display: flex;
    flex-direction: column;
    >span{
      font-size: .22rem;
      text-align: right;
      margin-bottom: .25rem;
    }
    >section{
      margin-top: .3rem;
      text-align: center;
      font-size: .22rem;
    }
    main >p{
        font-size: .26rem;
        font-weight: bold;
        color: #F06671;
        line-height: .38rem;
    }
    >button{
      color: #fff;
      height: .9rem;
      margin-top:.3rem;
      border: 1px solid #45BBF3;
      background: rgba(178,133,237,0.8);
      font-size: .32rem;
      font-weight: bold;
      color: #FFFFFF;
      text-shadow: 0px 2px 1px rgba(0,0,0,0.54);
    }
    .btns{
      display: flex;
      justify-content: space-between;
      width: 100%;
      >button{
          width:47%;
          font-size: .32rem;
          font-weight: bold;
          color: #FFFFFF;
          text-shadow: 0px 2px 1px rgba(0,0,0,0.54);
          margin-top: .32rem;
          height: .8rem;
          border: none;
          &:first-child{
            background: url(../../assets/img/btn-type-2.png) left top / 100% 100% no-repeat;
          }
          &:last-child{
            background: url(../../assets/img/btn-type-1.png) left top / 100% 100% no-repeat;

          }
      }
    }
    ::v-deep .el-input{
      font-size: .28rem;
      .el-input__inner{
          color: #fff;
          height: .8rem;
          background-color: rgba(85,114,241,0.5) !important;
          border: 1px solid #45BBF3 !important;
      }
    }
    .max-input{
      position: relative;
      >span{
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        color: #fff;
        width: 1.25rem;
        border: none;
        height: 100%;
        background: rgba(85, 114, 241) !important;
      }
    }
  }
</style>
